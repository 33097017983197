.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .hero-section {
  background-image: url('/src/Assets/hero_section_bg.jpg');
  background-size: cover;
  background-position: center;
  color: rgb(255, 255, 255);
  padding: 100px 0;
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.hero-section .btn {
  margin: 5px;
} */

html {
  scroll-behavior: smooth;
}

.hero-section {
  background-image: url('/src/Assets/hero_section_bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  color: #fff;
  padding: 100px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  transform: scale(1.1);
  filter: blur(5px);
}

.hero-section h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.hero-section .btn {
  margin: 5px;
}


.featured-skills {
  padding: 50px 0;
}

.featured-skills h2 {
  margin-bottom: 40px;
}

.featured-skills .card {
  height: 100%;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.featured-skills .card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.featured-skills .card-text {
  font-size: 1rem;
}

.portfolio-highlights {
  padding: 50px 0;
}

.portfolio-highlights h2 {
  margin-bottom: 40px;
}

.portfolio-highlights .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.portfolio-highlights .card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.portfolio-highlights .card-text {
  font-size: 1rem;
}

.portfolio-highlights .card-img-top {
  height: 200px;
  object-fit: cover;
}

.testimonials {
  padding: 50px 0;
}

.testimonials h2 {
  margin-bottom: 40px;
}

.testimonials .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonials .card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.testimonials .card-text {
  font-size: 1rem;
}

/* Add this in your CSS file */
.testimonials {
  margin-top: 2rem;
}

.testimonials .carousel-item {
  padding: 2rem 0;
}
/* Add this in your CSS file */
.blockquote {
  font-style: italic;
  font-size: 0.2rem;
  margin: 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-left: 5px solid #e9ecef;
}


.footer {
  padding: 30px 0;
}

.footer h5 {
  margin-bottom: 15px;
}

.footer p {
  margin-bottom: 10px;
}

.footer .page_url {
  text-decoration: none;
}

.about-page {
  padding: 50px 0;
}

.about-page h1 {
  margin-bottom: 30px;
}

.about-page .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-page .card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.about-page .card-text {
  font-size: 1rem;
}

.about-page .badge {
  font-size: 0.9rem;
  padding: 10px 15px;
  margin: 5px;
}

.about-page .btn-primary {
  margin-top: 20px;
}

.badge-container {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.contact-us-page {
  padding: 50px 0;
}

.services-page {
  padding: 50px 0;
}

.services-page h1 {
  margin-bottom: 30px;
}

.services-page .card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-page .card-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.services-page .card-text {
  font-size: 1rem;
}

.services-page .service-icon {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Styles for tilting effect */
.tilt-card {
  transition: transform 0.3s ease;
  transform-style: preserve-3d;
}

.tilt-card:hover {
  transform: rotateX(-1deg) rotateY(20deg);
  background-color: rgba(251, 249, 249, 0.97);
  opacity: 1;
}


/* Add to your CSS file */
.not-found-container {
  text-align: center;
  margin-top: 100px;
}

.not-found-container h1 {
  font-size: 100px;
  color: #f8d7da;
}

.not-found-container p {
  font-size: 24px;
}



.custom-navbar {
  background-color: #f8f9fa !important;
  padding: 1rem 0;
}

.navbar-brand-link {
  text-decoration: none;
  color: #343a40;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-link {
  color: #343a40 !important;
  font-size: 1rem;
  margin: 0 0.5rem;
  transition: color 0.2s ease-in-out;
}

.nav-link:hover {
  color: #007bff !important;
}

.active-link {
  color: #007bff !important;
  font-weight: bold;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.btn-outline-primary {
  border-color: #007bff;
  color: #007bff;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: #fff;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #0056b3;
}


.typewriter-container {
  overflow: hidden; /* Ensures the text will not overflow */
  white-space: nowrap; /* Keeps the text in one line */
  border-right: .15em solid orange; /* Creates the cursor effect */
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
  font-size: 1.5rem; /* Adjust font size as needed */
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}


/* Layout.css */
body, html, #root {
  height: 100%;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.mt-auto {
  margin-top: auto !important;
}


.navbar-brand-link {
  position: relative;
  text-decoration: none;
  color: inherit;
}

.navbar-brand-link em {
  text-decoration: none; /* Remove default underline for the <em> element */
}

.navbar-brand-link::after,
.navbar-brand-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: currentColor;
  left: 0;
}

.navbar-brand-link::after {
  bottom: -3px;
}

.navbar-brand-link::before {
  bottom: -7px;
}


.highlight {
  font-weight: bold;
  color: #007bff;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.1);
}
.highlight:hover {
  transform: scale(1.05);
  background-color: #5ea5f0;
  color: #fff;
}

.connect-button {
  transition: transform 0.3s, background-color 0.3s;
}

.connect-button:hover {
  transform: scale(1.05);
  background-color: #0056b3;
  color: #fff;
}
